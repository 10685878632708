import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "callbacks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#callbacks",
        "aria-label": "callbacks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Callbacks`}</h1>
    <p>{`Callbacks are functions that you can provide to a method in the SDK which will give you feedback when an event occurs.`}</p>
    <p>{`They can be useful when you want to record data & attributes at a particular stage of a transaction.`}</p>
    <h3 {...{
      "id": "sdkrendercreatetransaction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sdkrendercreatetransaction",
        "aria-label": "sdkrendercreatetransaction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`sdk.renderCreateTransaction`}</h3>
    <p>{`You can provide a set of callback events to the second parameter of `}<inlineCode parentName="p">{`renderCreateTransaction`}</inlineCode>{`.`}</p>
    <p>{`A list of callbacks provided are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Callback`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onSuccess(transaction)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoked when the transaction, claim (when applicable) & payment is successful.`}<br />{` `}<strong parentName="td">{`Warning:`}</strong>{` This callback will not be invoked if the user closes the modal before a successful transaction. You can use the `}<a parentName="td" {...{
              "href": "/sdk/transaction/getting-started/submit/#set-a-transaction-webhook"
            }}><inlineCode parentName="a">{`invoiceCompleted`}</inlineCode>{` webhook`}</a>{` to track the transaction outcome.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onError(error)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoked when an error occurs and the transaction cannot be completed. `}<br />{` `}<strong parentName="td">{`Warning:`}</strong>{` This callback will not be invoked if the user closes the modal and the transaction errors afterwards.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onCancel()`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoked when the transaction has been cancelled. `}<br />{` `}<strong parentName="td">{`Warning:`}</strong>{` This callback will not be invoked if the user closes the modal and the transaction is cancelled afterwards. You can use the `}<a parentName="td" {...{
              "href": "/sdk/transaction/getting-started/submit/#set-a-transaction-webhook"
            }}><inlineCode parentName="a">{`invoiceCancelled`}</inlineCode>{` webhook`}</a>{` to track the transaction outcome.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onCloseModal({ transaction, error, status })`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoked when the modal has been closed by the user and provides data at the current stage of the transaction.`}<br /><strong parentName="td">{`Example:`}</strong>{` it could be possible that the user closes the modal when a claim/payment is pending from the patient.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onTransactionCreated(transaction)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoked when the transaction has been created.`}<br /><strong parentName="td">{`Note:`}</strong>{` This does not mean that the transaction is successful yet, there could be a pending claim or payment.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onTransactionUpdated(transaction)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoked when the transaction has been updated.`}<br /><strong parentName="td">{`Note:`}</strong>{` A transaction is updated when a claim is created or submitted, or a payment has been made.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import medipassSDK from '@medipass/partner-sdk';

medipassSDK.renderCreateTransaction(
  {
    // ... transaction attributes
  },
  {
    onSuccess: function(transaction) {
      console.log(transaction);
      // -> { _id: '123', created: '2020-06-22T23:18:30.329', items: [...], claims: [...], ... }
    },
    onError: function(error) {
      console.log(error);
      // -> { message: 'An error occured.' }
    },
    onCancel: function() {
      console.log('Transaction cancelled.');
    },
    onCloseModal: function({ transaction, error, status }) {
      if (status === 'success') {
        console.log(transaction);
        // -> { _id: '123', created: '2020-06-22T23:18:30.329', items: [...], claims: [...], ... }
      } else if (status === 'error') {
        console.log(error);
        // -> { message: 'An error occured.' }
      } else if (status === 'cancelled') {
        console.log('Transaction cancelled.');
      }
    },
    onTransactionCreated: function(transaction) {
      console.log('Transaction created', transaction);
    },
    onTransactionUpdated: function(transaction) {
      console.log('Transaction updated', transaction);
    },
  }
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      